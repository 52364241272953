import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { mediaQuery } from 'src/styles'
import { orderServices } from 'src/utils'

export const ServiceListEx: React.FC = () => {
  return (
    <StaticQuery<GatsbyTypes.ServiceListQueryExQuery>
      query={graphql`
        query ServiceListQueryEx {
          allMarkdownRemark(filter: { fields: { slug: { regex: "^/service/" } } }) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  slug
                  title
                  summary
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 600, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const serviceList = data.allMarkdownRemark.edges
        // 並び替え
        orderServices(serviceList)
        return (
          !!serviceList && (
            <ScCardServiceWrapper>
              {serviceList.map((v: any) => (
                <ScCardService key={v.node.id}>
                  <Link to={v.node.fields.slug}>
                    {!!v.node.frontmatter.featuredImage && (
                      <ScCardServiceImg fluid={v.node.frontmatter.featuredImage.childImageSharp.fluid} alt="" />
                    )}
                    <ScCardServiceName>{v.node.frontmatter.title}</ScCardServiceName>
                    <ScCardServiceDesc>{v.node.frontmatter.summary}</ScCardServiceDesc>
                  </Link>
                </ScCardService>
              ))}
            </ScCardServiceWrapper>
          )
        )
      }}
    />
  )
}

const ScCardServiceWrapper = styled.div`
  > div {
    margin-bottom: 1.5rem;
  }
  ${mediaQuery('sm')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -24px;
    > div {
      margin-bottom: 0;
      padding: 24px;
      width: 50%;
    }
  }
`

const ScCardService = styled.div`
  a {
    color: ${({ theme }) => theme.color.text.main};
    display: block;
  }
`

const ScCardServiceImg = styled(Img)`
  margin-bottom: 1rem;
  ${mediaQuery('sm')} {
    margin-bottom: 1.5rem;
  }
`

const ScCardServiceName = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5em;
  ${mediaQuery('sm')} {
    font-size: 1.5rem;
  }
`

const ScCardServiceDesc = styled.div`
  font-size: 0.875rem;
  ${mediaQuery('sm')} {
    font-size: 1rem;
  }
`
