import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = ComponentStyleProps

export const PageDescription: React.FC<Props> = (props) => {
  const { children } = props
  return <ScDescription {...props}>{children}</ScDescription>
}

const ScDescription = styled.div`
  font-size: 1rem;
  white-space: pre-line;
  ${mediaQuery('sm')} {
    font-size: 1.25rem;
  }
`
