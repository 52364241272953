import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { PageHeading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import { PageDescription } from 'src/components/domains/pages/PageDescription'
import Layout from 'src/components/Layout'
import { ServiceListEx } from 'src/components/domains/service/ServiceListEx'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

const ServicePage: React.FC = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <PageMeta title={`サービス一覧 | ${siteTitle}`} />
      <Inner>
        <ScHeading component="h2" heading="サービス一覧" subHeading="Services" />

        <ScDescription>
          開発やサポートなど数々の独自の経験を活かし、お客様のビジネスゴール達成に向けた最適な施策をご提供します。
        </ScDescription>

        <ServiceListEx />
      </Inner>
    </Layout>
  )
}

export default ServicePage

const ScHeading = styled(PageHeading)`
  margin-bottom: 1.25rem;
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
  }
`

const ScDescription = styled(PageDescription)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`
